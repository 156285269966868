<template>
  <v-card rounded width="512" class="mx-auto my-10">
    <v-card-title>Select a provider</v-card-title>
    <v-card-text> You need to have a provider to use the scheduler </v-card-text>
    <v-card-text>
      <v-autocomplete
        :items="filteredProviders"
        :loading="loading"
        :search-input.sync="searchQuery"
        item-text="name"
        item-value="id"
        label="Select Provider"
        return-object
        v-model="selectedProvider"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        :disabled="!selectedProvider"
        @click="selectProvider(selectedProvider.id)"
        color="primary"
      >
        Select
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import GET_ALL_PROVIDERS from '../graphql/Query/GetAllProviders.gql';
import Provider from '../models/Provider';

export default {
  name: 'ProviderSelect',
  data: () => ({
    loading: true,
    providers: [],
    searchQuery: '',
    selectedProvider: null,
  }),
  computed: {
    filteredProviders() {
      return this.providers;
    },
  },
  async mounted() {
    this.selectedProvider = this.$store.state.user.info.providerId;
    const { data } = await this.$apollo.query({
      query: GET_ALL_PROVIDERS,
      fetchPolicy: 'no-cache',
    });
    this.providers = data.getAllProviders.map((p) => new Provider(p));
    this.loading = false;
  },
  methods: {
    selectProvider(providerId) {
      if (providerId) {
        this.$store.commit('saveUser', { providerId });
        this.$router.push({ name: 'Calendar' });
      }
    },
  },
};
</script>
